export const checkMalformedEvent = (message: string) => {
  let messageBody
  let jsonParsingError = false
  try {
    messageBody = JSON.stringify(JSON.parse(message ?? ''), null, 2)
  } catch {
    messageBody = message
    jsonParsingError = true
  }
  return { messageBody, jsonParsingError }
}
