import { InMemoryCache, ApolloClient } from '@apollo/client'

const serverUrl = '/api/service/depot-bff/v1/graphql'
const secondaryBaseUrl = `https://ws-sandbox-api.eng.toasttab.com`
const secondaryUrl = secondaryBaseUrl.concat(serverUrl)

export type ActiveEnvironment = 'main' | 'secondary'

export const getApolloClient = (activeEnv?: ActiveEnvironment) => {
  const isSecondaryEnv = activeEnv !== undefined && activeEnv === 'secondary'
  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: isSecondaryEnv ? secondaryUrl : serverUrl
  })
}
