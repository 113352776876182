import { useEffect, useState } from 'react'
import { Partner } from '@toasttab/depot-banquet-props'
import { PAYMENT_METHOD_DOMAIN } from '../api/config'
import useApi from './useApi'

interface CreatePaymentMethodDomainResponse {
  domain: string
}

export const useDeletePaymentMethodDomain = (partner: Partner) => {
  const [paymentMethodDomainId, setPaymentMethodDomainId] = useState<
    string | null
  >(null)

  const {
    loading,
    error,
    data,
    responseStatus: deleteResponseStatus,
    fetchApi
  } = useApi<CreatePaymentMethodDomainResponse>(
    paymentMethodDomainId
      ? `${PAYMENT_METHOD_DOMAIN.PATH}/${paymentMethodDomainId}`
      : '',
    'DELETE',
    partner.guid
  )

  const deletePaymentMethodDomain = (paymentMethodDomainId: string) => {
    setPaymentMethodDomainId(paymentMethodDomainId)
  }

  useEffect(() => {
    if (paymentMethodDomainId) {
      fetchApi()
    }
  }, [paymentMethodDomainId, fetchApi])

  return {
    loading,
    error,
    data,
    deleteResponseStatus,
    deletePaymentMethodDomain
  }
}
