import React, { useEffect, useState } from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button } from '@toasttab/buffet-pui-buttons'
import { PAYMENT_METHOD_DOMAIN } from '../../PaymentMethodsComponent/PaymentMethodConstants'
import { InfoTooltip, Tooltip } from '@toasttab/buffet-pui-tooltip'
import { Alert } from '@toasttab/buffet-pui-alerts'

interface DomainModalComponentProps {
  modalIsOpen: boolean
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  onSave: (domain: string) => void
}

interface ButtonState {
  status: boolean
  message: string
}

export const DomainModalComponent: React.FC<DomainModalComponentProps> = ({
  modalIsOpen,
  setModalIsOpen,
  onSave
}) => {
  const [domainName, setDomainName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [disableSaveButton, setDisableSaveButton] = useState<ButtonState>({
    status: false,
    message: ''
  })

  const closeModal = () => {
    setModalIsOpen(false)
    setDisableSaveButton({
      status: false,
      message: ''
    })
  }

  useEffect(() => {
    if (modalIsOpen) {
      setDomainName('')
    }
  }, [modalIsOpen])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setDomainName(value)

    // Regular expression for domain validation
    const domainRegex = /^((?!-)[\w-]{1,63}(?<!-)\.)+[\w-]{2,63}$/
    if (value.length <= 253 && domainRegex.test(value)) {
      setDisableSaveButton({
        status: false,
        message: ''
      })
    } else {
      setDisableSaveButton({
        status: true,
        message: PAYMENT_METHOD_DOMAIN.INVALID_DOMAIN_MESSAGE
      })
    }
  }

  const handleSave = () => {
    setLoading(true)
    try {
      onSave(domainName)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className='text-default'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      size='lg'
    >
      <Modal.Header>
        {PAYMENT_METHOD_DOMAIN.REGISTER_DOMAIN}
        <p className='type-subhead font-normal text-secondary mt-2'>
          {PAYMENT_METHOD_DOMAIN.SUBHEADING}
        </p>
      </Modal.Header>

      <Modal.Body className='mt-4'>
        <div className='mb-6'>
          <label
            htmlFor='domain-name'
            className='font-semibold type-default text-default'
          >
            {PAYMENT_METHOD_DOMAIN.LABEL}
          </label>
          <InfoTooltip size='sm' placement='right'>
            {PAYMENT_METHOD_DOMAIN.DOMAIN_NAME_TOOLTIP}
          </InfoTooltip>
          <TextInput
            value={domainName}
            name='domain-name'
            onChange={handleInputChange}
            disabled={false}
            transparentBackground={true}
          />
          <p className='type-caption mt-2'>{PAYMENT_METHOD_DOMAIN.CAPTION}</p>
          <Alert className='mt-8'>{PAYMENT_METHOD_DOMAIN.REGISTER_INFO}</Alert>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className='flex-grow sm:flex-none'
          variant='link'
          onClick={closeModal}
          disabled={loading}
        >
          {PAYMENT_METHOD_DOMAIN.CANCEL}
        </Button>

        <Tooltip accessibility='label'>
          <Tooltip.Trigger role='button'>
            <Button
              className='flex-grow sm:flex-none'
              onClick={handleSave}
              disabled={disableSaveButton?.status || loading}
            >
              {PAYMENT_METHOD_DOMAIN.REGISTER}
            </Button>
          </Tooltip.Trigger>

          {disableSaveButton?.status && (
            <Tooltip.Content>{disableSaveButton?.message}</Tooltip.Content>
          )}
        </Tooltip>
      </Modal.Footer>
    </Modal>
  )
}
