import React, { useState } from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { PaymentMethodDomainType } from '../../../hooks/useFetchPaymentMethodDomain'

interface DeleteDomainModalComponentProps {
  modalIsOpen: boolean
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  onDelete: (id: string) => void
  deleteData: PaymentMethodDomainType | null
}

export const DeleteDomainModalComponent: React.FC<
  DeleteDomainModalComponentProps
> = ({ modalIsOpen, setModalIsOpen, onDelete, deleteData }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const handleDelete = () => {
    setLoading(true)
    try {
      if (deleteData) onDelete(deleteData.id)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className='text-default'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      size='sm'
    >
      <Modal.Header>Delete Domain ?</Modal.Header>

      <Modal.Body>
        <div className='mb-4'>
          <Alert className='mt-4'>
            {`Are you sure you want to remove ${deleteData?.domain} ?`}
          </Alert>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className='flex-grow sm:flex-none'
          variant='link'
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          className='flex-grow sm:flex-none'
          onClick={handleDelete}
          disabled={loading}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
