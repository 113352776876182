import React, { useEffect, useState } from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { PAYMENT_METHOD_DOMAIN } from '../../PaymentMethodsComponent/PaymentMethodConstants'
import { CopyIcon, LaunchIcon } from '@toasttab/buffet-pui-icons'
import { PaymentMethodDomainType } from '../../../hooks/useFetchPaymentMethodDomain'
import { InfoTooltip, Tooltip } from '@toasttab/buffet-pui-tooltip'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Stepper } from '@toasttab/buffet-pui-stepper'

interface DomainModalComponentProps {
  modalIsOpen: boolean
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  validate: (domain: string) => void
  validateLoading: boolean
  error?: string | null
  modalData: PaymentMethodDomainType | null
}

interface ButtonState {
  status: boolean
  message: string
}

export const VerifyDomainModalComponent = ({
  modalIsOpen,
  setModalIsOpen,
  validate,
  validateLoading,
  error,
  modalData
}: DomainModalComponentProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [textToCopy, setTextToCopy] = useState<string>('')

  const [disableSaveButton, setDisableSaveButton] = useState<ButtonState>({
    status: false,
    message: ''
  })

  useEffect(() => {
    if (modalData) {
      setTextToCopy(
        `${modalData.domain}/.well-known/apple-developer-merchantid-domain-association`
      )
    }
  }, [modalData])

  const closeModal = () => {
    setModalIsOpen(false)
    setDisableSaveButton({
      status: false,
      message: ''
    })
  }

  const handleOpenInNewTab = () => {
    window.open(
      'https://www.toasttab.com/.well-known/ME_prod_toast',
      '_blank',
      'noopener'
    )
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(textToCopy)
  }

  const handleVerify = async () => {
    setLoading(true)
    try {
      if (modalData) validate(modalData.domain)
    } catch (error) {
      console.error('Validation error:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className='text-default'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      size='xl'
    >
      <Modal.Header>
        {PAYMENT_METHOD_DOMAIN.REGISTER_DOMAIN}
        <p className='type-subhead font-normal text-secondary mt-2'>
          {PAYMENT_METHOD_DOMAIN.SUBHEADING}
        </p>
      </Modal.Header>

      <Modal.Body className='mt-4'>
        {error && (
          <div className='mt-4 mb-4'>
            <Alert variant='error'>{error}</Alert>
          </div>
        )}
        <div className='flex items-start space-x-6 mb-6'>
          <div className='flex-grow'>
            <Stepper
              orientation='vertical'
              type='number'
              containerClassName='h-[185px]'
              steps={[{ state: 'complete' }, { state: 'complete' }]}
            />
          </div>

          <div className='flex-grow'>
            <div className='mb-6'>
              <label className='font-semibold type-default text-default'>
                {PAYMENT_METHOD_DOMAIN.GET_VERIFICATION_FILE_LABEL}
                <InfoTooltip size='sm' placement='right'>
                  {PAYMENT_METHOD_DOMAIN.VERIFICATION_FILE_INFO_MESSAGE}
                </InfoTooltip>
              </label>
              <>
                <div>
                  <Button
                    variant='secondary'
                    size='sm'
                    iconRight={<LaunchIcon accessibility='decorative' />}
                    onClick={handleOpenInNewTab}
                  >
                    {PAYMENT_METHOD_DOMAIN.OPEN_NEW_TAB_BUTTON_LABEL}
                  </Button>
                </div>
                <p className='type-caption mt-2'>
                  Save this file as
                  <strong>
                    &nbsp; apple-developer-merchantid-domain-association
                  </strong>
                  .<br></br>Please note that the verification file does not have
                  a file extension.
                </p>
              </>
            </div>

            <div className='mb-6'>
              <label className='font-semibold type-default text-default'>
                {PAYMENT_METHOD_DOMAIN.HOST_VERIFICATION_FILE_LABEL}
              </label>
              <div>
                <div>
                  <p>{PAYMENT_METHOD_DOMAIN.HOST_INSTRUCTIONS}</p>
                </div>
                <div className='mt-2 type-caption'>
                  <Alert showIcon={false}>
                    <div className='flex'>
                      <span>
                        <IconButton
                          icon={<CopyIcon testId='copy' />}
                          onClick={handleCopy}
                        />
                      </span>
                      <span className='align-start'>{textToCopy}</span>
                    </div>
                  </Alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className='flex-grow sm:flex-none'
          variant='link'
          onClick={closeModal}
          disabled={validateLoading || loading}
        >
          {PAYMENT_METHOD_DOMAIN.CANCEL}
        </Button>

        <Tooltip accessibility='label'>
          <Tooltip.Trigger role='button'>
            <Button
              className='flex-grow sm:flex-none'
              onClick={handleVerify}
              disabled={disableSaveButton?.status || loading}
            >
              {PAYMENT_METHOD_DOMAIN.VERIFY}
            </Button>
          </Tooltip.Trigger>

          {disableSaveButton?.status && (
            <Tooltip.Content>{disableSaveButton?.message}</Tooltip.Content>
          )}
        </Tooltip>
      </Modal.Footer>
    </Modal>
  )
}
