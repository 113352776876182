import { useState, useCallback } from 'react'

type ApiMethod = 'GET' | 'POST' | 'DELETE'

interface UseApiResponse<T> {
  loading: boolean
  error: ApiError | null
  data: T | null
  responseStatus?: number | null
  fetchApi: (body?: T) => Promise<void>
}

// Custom Error class to capture HTTP status and other details
export class ApiError extends Error {
  status: number
  statusText: string

  constructor(message: string, status: number, statusText: string) {
    super(message)
    this.status = status
    this.statusText = statusText
    this.name = 'ApiError'
  }
}

const useApi = <T>(
  url: string,
  method: ApiMethod,
  partnerGuid: string
): UseApiResponse<T> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ApiError | null>(null)
  const [data, setData] = useState<T | null>(null)
  const [responseStatus, setResponseStatus] = useState<number | null>(null)

  const fetchApi = useCallback(
    async (body?: T) => {
      setLoading(true)
      setError(null)
      setData(null)
      setResponseStatus(null)

      try {
        const headers: HeadersInit = {
          'toast-organization-guid': partnerGuid
        }

        if (method === 'POST') {
          headers['Content-Type'] = 'application/json'
        }

        const options: RequestInit = {
          method,
          headers,
          body: method === 'POST' ? JSON.stringify(body) : undefined
        }

        const response = await fetch(url, options)

        if (!response.ok) {
          // Create a custom error with status and statusText
          const errorText = await response.text()
          throw new ApiError(
            `Error ${response.status}: ${errorText}`,
            response.status,
            response.statusText
          )
        }

        setResponseStatus(response.status)

        const responseData: T = await response.json()
        setData(responseData)
      } catch (err) {
        setError(
          err instanceof ApiError
            ? err
            : new ApiError('Unknown error', 500, 'Internal Server Error')
        )
        setData(null)
      } finally {
        setLoading(false)
      }
    },
    [url, method, partnerGuid]
  )

  return { loading, responseStatus, error, data, fetchApi }
}

export default useApi
