import React, { useState, useEffect } from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useCreatePaymentMethodConfig } from '../../hooks/useCreatePaymentMethodConfigs'
import { useUpdatePaymentMethodConfig } from '../../hooks/useUpdatePaymentMethodConfigs'
import {
  SelectedPaymentMethods,
  SavePaymentMethodConfig,
  defaultPaymentConfigurations,
  PaymentMethodConfig,
  CreatePaymentMethodConfig
} from '../../typedefs/payment-method-configs/PaymentMethodConfigs'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  CheckCircleSelectedIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { PAYMENT_METHOD_CONFIG } from '../PaymentMethodsComponent/PaymentMethodConstants'
import PMDisplayConfigurationComponent from './PMDisplayConfigurationComponent'
import { isEmpty } from 'lodash'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

interface ModalComponentProps {
  modalData: PaymentMethodConfig | null
  edit: boolean
  modalIsOpen: boolean
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  fetchNewConfigData: () => void
}

interface ButtonState {
  status: boolean
  message: string
}

export const ConfigModalComponent: React.FC<ModalComponentProps> = ({
  modalData,
  edit,
  modalIsOpen,
  setModalIsOpen,
  fetchNewConfigData
}) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const [configName, setConfigName] = useState('')

  const [disableSaveButton, setDisableSaveButton] = useState<ButtonState>({
    status: false,
    message: ''
  })
  const { partner } = useDepotBanquetProps()
  const [selectedPaymentMethods, setSelectedPaymentMethods] =
    useState<SelectedPaymentMethods>(defaultPaymentConfigurations)

  const handleUpdatePaymentMethods = (updatedPaymentMethods: any) => {
    setSelectedPaymentMethods(updatedPaymentMethods)
  }

  const {
    loading: createLoading,
    data: createData,
    createPaymentMethodConfig
  } = useCreatePaymentMethodConfig(partner)
  const {
    loading: updateLoading,
    data: updateData,
    updatePaymentMethodConfig
  } = useUpdatePaymentMethodConfig(partner)
  const loading = createLoading || updateLoading

  const closeModal = () => {
    setConfigName('')
    setSelectedPaymentMethods(defaultPaymentConfigurations)
    setModalIsOpen(false)
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConfigName(e.target.value)

  const handleSave = async () => {
    let updateConfig: SavePaymentMethodConfig
    try {
      if (modalData) {
        updateConfig = {
          id: modalData?.id,
          name: configName,
          card: selectedPaymentMethods.card,
          applePay: selectedPaymentMethods.applePay,
          googlePay: selectedPaymentMethods.googlePay
        }

        await updatePaymentMethodConfig(updateConfig)
        fetchNewConfigData()
        showSuccessSnackBar(
          <>
            <CheckCircleSelectedIcon accessibility='decorative' /> Configuration
            updated
          </>,
          { autoHideDuration: 4000 }
        )
      } else {
        throw new Error('No configuration added')
      }
    } catch (error) {
      showErrorSnackBar(
        <>
          <WarningOutlineIcon accessibility='decorative' /> Configuration could
          not be updated
        </>,
        { autoHideDuration: 4000 }
      )
    }
  }

  const handleCreate = async () => {
    try {
      const createConfig: CreatePaymentMethodConfig = {
        name: configName,
        card: selectedPaymentMethods.card,
        applePay: selectedPaymentMethods.applePay,
        googlePay: selectedPaymentMethods.googlePay
      }
      await createPaymentMethodConfig(createConfig)
      fetchNewConfigData()
      showSuccessSnackBar(
        <>
          <CheckCircleSelectedIcon accessibility='decorative' /> Configuration
          updated
        </>,
        {
          autoHideDuration: 4000,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onDismiss: () => {}
        }
      )
    } catch (error) {
      showErrorSnackBar(
        <>
          <WarningOutlineIcon accessibility='decorative' /> Configuration could
          not be created
        </>,
        {
          autoHideDuration: 4000,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onDismiss: () => {}
        }
      )
    }
  }

  useEffect(() => {
    if (createData || updateData) {
      closeModal()
    }
  }, [createData, updateData])

  useEffect(() => {
    if (!edit) {
      setConfigName('')
      setSelectedPaymentMethods(defaultPaymentConfigurations)
    } else if (edit && modalData) {
      if (modalData.name) {
        setConfigName(modalData?.name)
      }
      setSelectedPaymentMethods({
        card: {
          displayPreference: modalData?.card?.displayPreference
        },
        applePay: {
          displayPreference: modalData?.applePay?.displayPreference
        },
        googlePay: {
          displayPreference: modalData?.googlePay?.displayPreference
        }
      })
    }
  }, [edit, modalData, modalIsOpen])

  useEffect(() => {
    let errorMessage = ''

    const allOff = Object.values(selectedPaymentMethods).every(
      (method) => method.displayPreference === 'OFF'
    )

    const isNameEmpty = isEmpty(configName)

    if (allOff) {
      errorMessage = PAYMENT_METHOD_CONFIG.PAYMENT_METHOD_EMPTY_MESSAGE
    } else if (isNameEmpty) {
      errorMessage = PAYMENT_METHOD_CONFIG.CONFIG_NAME_EMPTY_MESSAGE
    }

    setDisableSaveButton({
      status: allOff || isEmpty(configName),
      message: errorMessage
    })
  }, [selectedPaymentMethods, configName])

  return (
    <Modal
      className='text-default'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      size='lg'
    >
      <Modal.Header>
        {edit ? PAYMENT_METHOD_CONFIG.EDIT : PAYMENT_METHOD_CONFIG.ADD}
      </Modal.Header>
      <Modal.Body className='mt-4'>
        <TextInput
          label={PAYMENT_METHOD_CONFIG.NAME_LABEL}
          placeholder={PAYMENT_METHOD_CONFIG.NAME_PACEHOLDER}
          name='config-name'
          value={configName}
          size='lg'
          transparentBackground={true}
          onChange={handleInputChange}
        />
        <PMDisplayConfigurationComponent
          initialPaymentMethods={selectedPaymentMethods}
          onUpdatePaymentMethods={handleUpdatePaymentMethods}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='flex-grow sm:flex-none'
          variant='link'
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Tooltip accessibility='label'>
          <Tooltip.Trigger role='button'>
            <Button
              className='flex-grow sm:flex-none'
              onClick={edit ? handleSave : handleCreate}
              disabled={loading || disableSaveButton?.status}
            >
              {edit ? 'Update' : 'Save'}
            </Button>
          </Tooltip.Trigger>

          {disableSaveButton?.status && (
            <Tooltip.Content>{disableSaveButton.message}</Tooltip.Content>
          )}
        </Tooltip>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfigModalComponent
