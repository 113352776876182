import { DividingLine, Title } from '@toasttab/buffet-pui-config-templates'
import { ConfigurationComponent } from '../ConfigurationComponent/ConfigurationComponent'
import { DomainComponent } from '../DomainComponent/DomainComponent'

export const PaymentMethodComponent = () => {
  return (
    <div className='px-40 flex flex-col space-y-8 bg-gray-0 pb-8 pt-6'>
      <Title> Payment methods</Title>
      <DividingLine />

      <div className='flex flex-col space-y-8'>
        <ConfigurationComponent />
        <DomainComponent />
      </div>
    </div>
  )
}
